import {
  SignInFormType,
  SignUpFormType,
  ForgotPasswordType,
  ResetPassType,
  ChangePassType,
} from "types/authForm";
import HttpAdapter from "./httpAdapter";

class AuthService extends HttpAdapter {
  signIn(formData: SignInFormType) {
    return this.get("/auth/login", { params: formData });
  }

  signUp(formData: SignUpFormType) {
    return this.post("/auth/register", formData);
  }

  forgotPassword(formData: ForgotPasswordType) {
    return this.post("/auth/forgot-password", formData);
  }

  resetPass(formData: ResetPassType) {
    return this.post("/auth/reset-password", formData);
  }

  signOut() {
    return this.get("/auth/logout");
  }

  reAuthenticate() {
    return this.get("/auth/is-authenticated");
  }

  changePass(formData: ChangePassType) {
    return this.post("/auth/change-password", formData);
  }
}

export default AuthService;
